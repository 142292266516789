import React from 'react';
import axios from "axios";
import {BASE_URL} from "../Constants";
import ErrorBox from "../ErrorBox";
import Button from "../Button";
import DatePicker from "react-date-picker";


class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",

            errors: [],
            errorMessage: null,
            isSent: false,
            isSending: false,
            reference: "",
            date: null,
            company: "",
            firstname: "",
            lastname: "",
            street: "",
            city: "",
            zip: "",
            phone: "",
            kennzeichen: "",
            show: "form"
        };

        this.handleChange = this.handleChange.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.send = this.send.bind(this);
        this.copy = this.copy.bind(this);
    }

    onFromDateChange(event) {
        const value = event;

        this.setState(prevState => ({
            "date": value
        }));
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

     replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: (event.target.name == "reference") ? this.replaceAll(this.replaceAll(value, " ", ""), "/", "~") : value,
            errors: []
        });
    }

    send() {
        this.setState({ isSending: true });

        let found = this;
        setTimeout(() => {
            found.setState({ isSending: false, show: "final"});
        //    toast("Link wurde kopiert!")
        }, 3000);


        /*
        axios.post(BASE_URL + '/auth/reset-pin', {
            email: this.state.email,
        })
            .then(() => {

            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });

         */
    }


    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    copy() {
        navigator.clipboard.writeText("http://app.claims-report.de/ref/"+this.state.reference);
        alert("Erfolgreich kopiert!")
        return false;
    }

    Form() {
        return (
            this.state.show != "form" ? <div>
                <div style={{ width: 300, margin: "auto", textAlign: "center"}}>
                    <img src={'/logo.jpg'} style={{ width: 300, margin: "auto", textAlign: "center"}}/>
                </div>

                <div style={{width: "100%", marginTop: 30, marginBottom: 30, borderRadius: 20, padding: 20, backgroundColor: "#4facfe"}}>

                    <h3 style={{color: '#fff', textAlign: "center", marginBottom: 0, marginTop: 0}}>Link zum Verteilen</h3>
                    <p style={{color: '#fff', textAlign: "center", marginBottom: 0, marginTop: 15}}>http://app.claims-report.de/ref/{this.state.reference}</p>
                    <div style={{textAlign: "right"}}>
                        <a href={"#"} onClick={this.copy()}><img src={"/copy.png"} style={{width: 35, height: 35}}/></a>
                    </div>
                </div>

            </div> : <div>
                <div style={{ width: 300, margin: "auto", textAlign: "center"}}>
                    <img src={'/logo.jpg'} style={{ width: 300, margin: "auto", textAlign: "center"}}/>
                </div>

                <p className="text" style={{marginTop: 30, marginBottom: 30}}>Hier kommt ein Text. Hier kommt ein Text. Hier kommt ein Text. Hier kommt ein Text. </p>

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Schadensdatum (optional)</p>
                <DatePicker
                    className="dateField"
                    value={ this.state.date }
                    onChange={ this.onFromDateChange }
                    maxDate={new Date()}
                />

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Schadensnummer / Referenznummer</p>
                <input type="text" placeholder="Schadensnummer / Referenznummer" name="reference" value={this.state.reference} onChange={this.handleChange} />

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Kennzeichen (optional)</p>
                <input type="text" placeholder="Kennzeichen (optional)" name="kennzeichen" value={this.state.kennzeichen} onChange={this.handleChange} />

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold", fontSize: 20 }}>Versicherungsnehmer</p>

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Firma (optional)</p>
                <input type="text" placeholder="Firma (optional)" name="company" value={this.state.company} onChange={this.handleChange} />

                <div style={{flexDirection: "row", display: "flex"}}>
                    <div style={{flex: 1, marginRight: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Vorname</p>
                        <input type="text" placeholder="Vorname" name="firstname" value={this.state.firstname} onChange={this.handleChange} />
                    </div>
                    <div style={{flex: 1, marginLeft: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Nachname</p>
                        <input type="text" placeholder="Nachname" name="lastname" value={this.state.lastname} onChange={this.handleChange} />
                    </div>
                </div>

                <div style={{flexDirection: "row", display: "flex"}}>
                    <div style={{flex: 1, marginRight: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Straße</p>
                        <input type="text" placeholder="Straße" name="street" value={this.state.street} onChange={this.handleChange} />
                    </div>
                    <div style={{flex: .5, marginLeft: 15, marginRight: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>PLZ</p>
                        <input type="text" placeholder="PLZ" name="zip" value={this.state.zip} onChange={this.handleChange} />
                    </div>
                    <div style={{flex: 1, marginLeft: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Stadt</p>
                        <input type="text" placeholder="Ort" name="city" value={this.state.city} onChange={this.handleChange} />
                    </div>
                </div>

                <div style={{flexDirection: "row", display: "flex"}}>
                    <div style={{flex: 1, marginRight: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Telefon</p>
                        <input type="text" placeholder="Telefon" name="phone" value={this.state.phone} onChange={this.handleChange} />
                    </div>
                    <div style={{flex: 1, marginLeft: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>E-Mail</p>
                        <input type="text" placeholder="E-Mail" name="email" value={this.state.email} onChange={this.handleChange} />
                    </div>
                </div>

                {
                    this.ErrorsView()
                }

                <Button title="Schadenslink erstellen"
                           showLoadingState={ this.state.isSending }
                           onClick={ this.send }
                           disabled={ this.state.reference.length === 0 }
                />
            </div>
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title"></p>
                </div>

                <div className="content">
                    {
                        this.Form()
                    }
                </div>
            </div>
        );
    }
}

export default Index;
