import React from 'react';
import {useHistory} from "react-router-dom";

function ChatListCell(props) {
    let history = useHistory();

    function showDetails() {
        history.push("/chat/" + props.item.id);
    }

    return (
        <div className="importItem-cell" onClick={ showDetails }>
            <div className="importItem-cell-left">
                {
                    props.item.import_request.images.length > 0 ?
                        <div className="importItem-cell-image" style={{ backgroundImage: "url('" + props.item.import_request.images[0].url + "')" }} />
                        :
                        <div className="importItem-cell-image" style={{ backgroundImage: "url('/image-regular.svg')", backgroundSize: "60px" }} />
                }

                {
                    props.item.to_user.image !== null ?
                        <div className="importItem-cell-profileImage" style={{ backgroundImage: "url('" + props.item.to_user.image.url + "')" }} />
                        :
                        <div className="importItem-cell-profileImage" style={{ backgroundImage: "url('/user-solid.svg')", backgroundSize: "20px" }}/>
                }
            </div>

            <div className="importItem-cell-right">
                <p className="importItem-cell-label" style={{ color: "gray" }}>{ props.item.to_user.profile.firstname } { props.item.to_user.profile.lastname }</p>
                <p className="importItem-cell-label" style={{ fontWeight: "700" }}>{ props.item.import_request.name }</p>
                <p className="importItem-cell-label" style={{ color: "#0c9dab" }}>{ props.item.import_request.price_min } - { props.item.import_request.price_max } €</p>
            </div>
        </div>
    );
}

export default ChatListCell;
