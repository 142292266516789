import React from 'react';

function ChatBubble(props) {
    return (
        <div className="chatBubble-container"
             style={{
                 justifyContent: props.item.position === "left" ? "flex-start" : "flex-end",
             }}
        >

            {
                props.item.type === "text" ?
                    <div
                        className="chatBubble"
                        style={{
                            backgroundColor:
                                props.item.position === "left" ? "#e6e5ea" : "#606569",
                            color: props.item.position === "left" ? "black" : "white",
                        }}
                        onClick={() => props.item.image != null ? window.open(props.item.image[0].url, '_blank', 'noreferrer') : null}
                    >

                        {props.item.image != null ? props.item.message+":" : props.item.message}
                        {props.item.image != null ? <><br /><br /></> : null}
                        {props.item.image != null ? props.item.image[0].filename : null}

                    </div>
                    :
                    props.item.image != null ? <div className="chatBubble-image" style={{
                            backgroundImage: "url('" + props.item.image[0].url + "')"
                        }}
                        /> : ""
            }

        </div>
    );
}

export default ChatBubble;
