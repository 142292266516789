import React from 'react';
import Button from "./Button";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

function ConfirmationView(props) {
    let history = useHistory();

    function showMainMenu() {
        history.push("/");
    }

    return (
        <div className="messageContainer">
            <div className="iconCircle" style={{ backgroundImage: "url('/" + props.icon + "')"}}/>

            <p className="text">{ props.text }</p>

            <Button title={ props.buttonTitle ? props.buttonTitle : "Fertig" } onClick={ showMainMenu } />
        </div>
    );
}

ConfirmationView.propTypes = {
    text: PropTypes.string.isRequired,
    route: PropTypes.string,
    buttonTitle: PropTypes.string
};

export default ConfirmationView;
