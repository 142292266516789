import React from 'react';
import AuthManager from "./AuthManager";

function Main() {

    function logout() {
        let authManager = new AuthManager();
        authManager.logout();
    }

    return (
        <div className="wrapper">
            <div className="titlebar">
                <p className="titlebar-title">Debug</p>
            </div>
            <div className="content">
                Logged in.

                <button onClick={ logout }>Logout</button>
            </div>
        </div>
    );
}

export default Main;
