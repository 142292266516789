import React from 'react';
import axios from "axios";
import {BASE_URL} from "./Constants";
import ErrorBox from "./ErrorBox";
import Button from "./Button";

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",

            errors: [],
            errorMessage: null,
            isSent: false,
            isSending: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.sendResetMail = this.sendResetMail.bind(this);
        this.showSetPassword = this.showSetPassword.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: []
        });
    }

    sendResetMail() {
        this.setState({ isSending: true });

        axios.post(BASE_URL + '/auth/reset-pin', {
            email: this.state.email,
        })
            .then(() => {
                this.showSetPassword();
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }

    showSetPassword() {
        this.props.history.push("/forgotPassword/set");
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    Form() {
        return (
            <div>
                <div className="iconCircle" style={{ backgroundImage: "url('/user-solid.svg')"}}/>

                <p className="text">Geben Sie Ihre E-Mail Adresse ein, um einen PIN zu erhalten. Mit diesem können Sie ein neues Passwort erstellen.</p>

                <input type="text" placeholder="Deine E-Mail Adresse" name="email" value={this.state.email} onChange={this.handleChange} />

                {
                    this.ErrorsView()
                }

                <Button title="Senden"
                           showLoadingState={ this.state.isSending }
                           onClick={ this.sendResetMail }
                           disabled={ this.state.email.length === 0 }
                />
            </div>
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Passwort vergessen</p>
                </div>

                <div className="content">
                    {
                        this.Form()
                    }
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
