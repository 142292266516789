import React from 'react';
import {BASE_URL} from "./Constants";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import EditField from "./EditField";
import Button from "./Button";
import SelectField from "./SelectField";
import ErrorBox from "./ErrorBox";

class Profile extends React.Component {

    state = {
        isLoading: true,
        isEditing: false,
        profile: [],
        errors: null,
        errorMessage: null,
        isSending: false,
        newImage: null
    }

    constructor(props) {
        super(props);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.showChangePassword = this.showChangePassword.bind(this);
        this.showDeleteAccount = this.showDeleteAccount.bind(this);
        this.chooseFile = this.chooseFile.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get(BASE_URL + "/user/profile/index")
            .then(result => {
                const profile = result.data.data;

                if (profile.profile !== null) {
                    this.setState({
                        profile: {
                            firstname: profile.profile.firstname,
                            lastname: profile.profile.lastname,
                            telephone: profile.profile.telephone,
                            language: profile.profile.language,
                            company_name: profile.company_name,
                            street: profile.address.street,
                            zip: profile.address.zip,
                            city: profile.address.city,
                            country: profile.address.country,
                            salutation: profile.profile.salutation ?? "sir",
                            paypal: profile.profile.paypal,
                            image: profile.image
                        },
                        isLoading: false,
                        errors: null,
                        errorMessage: null,
                        newImage: null,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        errors: null,
                        errorMessage: null
                    });
                }
            });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    chooseFile() {
        this.state.inputElement.click();
    }

    onFileChange(event) {
        this.setState({
            newImage: event.target.files[0],
            newImagePreview: URL.createObjectURL(event.target.files[0])
        });
    }

    ProfileImageEditButton() {
        return (
            this.state.isEditing ?
                <div className="profile-profilePicture-editButton" onClick={ this.chooseFile } />
                : null
        );
    }

    ProfileView() {
        return (
            <div>
                {
                    this.state.newImage !== null ?
                        <div className="profile-profilePicture" style={{ backgroundImage: "url('user-solid.svg')" }}>
                            <img style={{ width: "180px", height: "180px", borderRadius: "90px" }} src={ this.state.newImagePreview } alt=""/>
                            { this.ProfileImageEditButton() }
                        </div>
                        :
                    this.state.profile.image === null ?
                        <div className="profile-profilePicture" style={{ backgroundImage: "url('user-solid.svg')" }}>
                            {
                                this.ProfileImageEditButton()
                            }
                        </div>
                        :
                        <div className="profile-profilePicture" style={{ backgroundImage: "url('" + this.state.profile.image.url + "')", backgroundSize: "cover" }}>
                            {
                                this.ProfileImageEditButton()
                            }
                        </div>
                }

                <form>
                    <input type="file" className="hiddenUpload" accept=".png,.jpeg,.jpg" onChange={ this.onFileChange }
                           ref={input => this.state.inputElement =  input }
                    />
                </form>

                <p className="heading-small">{ this.state.profile.firstname } { this.state.profile.lastname }</p>
                <div className="spacer20" />
                <div className="spacer20" />

                <EditField label="Vorname" name="firstname" value={ this.state.profile.firstname ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />
                <EditField label="Nachname" name="lastname" value={ this.state.profile.lastname ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />

                <div className="spacer20" />

                <SelectField name="salutation" label="Anrede" onChange={ this.handleFieldChange }
                                disabled={ !this.state.isEditing }
                                value={this.state.profile.salutation ?? "sir"} items={
                    [<option key="sir" value="sir">Sehr geehrter Herr</option>, <option key="madam" value="madam">Sehr geehrte Frau</option>, <option key="neutral" value="neutral">Sehr geehrte Damen und Herren</option>]
                }
                />
                <EditField label="Telefon" name="telephone" value={ this.state.profile.telephone ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />
                <SelectField name="language" label="Sprache" onChange={ this.handleFieldChange }
                             disabled={ !this.state.isEditing }
                             value={this.state.profile.language ?? "en_US"} items={
                    [<option key="en_US" value="en_US">Englisch</option>, <option key="de_DE" value="de_DE">Deutsch</option>]
                }
                />

                <div className="spacer20" />

                <EditField label="Straße" name="street" value={ this.state.profile.street ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />
                <EditField label="PLZ" name="zip" value={ this.state.profile.zip ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />
                <EditField label="Stadt" name="city" value={ this.state.profile.city ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />
                <EditField label="Land" name="country" value={ this.state.profile.country ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />

                <div className="spacer20" />

                <EditField label="PayPal.Me-Link" name="paypal" value={ this.state.profile.paypal ?? "" } onChange={ this.handleFieldChange } disabled={ !this.state.isEditing } />


                <div className="spacer20" />
                <div className="spacer20" />

                <Button showLoadingState={ this.state.isSending } title={ this.state.isEditing ? "Profil sichern" : "Profil bearbeiten" } onClick={ this.toggleEditMode } />

                {
                    this.ErrorsView()
                }

                {
                    !this.state.isEditing ?
                        <div>
                            <Button title="Mein Passwort ändern" onClick={ this.showChangePassword } />
                            <Button title="Konto löschen ..." onClick={ this.showDeleteAccount } isDestructive={true} />
                        </div>
                        : null
                }
            </div>
        );
    }

    handleFieldChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: value,
            }
        }));
    }

    toggleEditMode() {
        if (this.state.isEditing) {
            this.saveProfile();
        } else {
            this.setState({ isEditing: !this.state.isEditing });
        }
    }

    showChangePassword() {
        this.props.history.push("/profile/password");
    }

    showDeleteAccount() {
        this.props.history.push("/profile/delete");
    }

    saveProfile() {

        this.setState({  isSending: true });

        const url = BASE_URL + '/user/profile/update';
        const formData = new FormData();

        if (this.state.newImage !== null) {
            formData.append('image', this.state.newImage);
        }

        formData.append('salutation', this.state.profile.salutation ?? "sir");
        formData.append('firstname', this.state.profile.firstname);
        formData.append('lastname', this.state.profile.lastname);
        formData.append('telephone', this.state.profile.telephone);
        formData.append('street', this.state.profile.street);
        formData.append('zip', this.state.profile.zip);
        formData.append('city', this.state.profile.city);
        formData.append('language', this.state.profile.language);
        formData.append('paypal', this.state.profile.paypal);

        const config = {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data'
            }
        }

        return axios.post(url, formData, config)
            .then(() => {
                this.setState({ isSending: false, isEditing: false, errors: null, errorMessage: null });

                this.loadData();
            }).catch(error => {
                console.log(error.response)
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Mein Profil</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.ProfileView()
                    }
                </div>
            </div>
        );
    }
}

export default Profile;
