import React from 'react';
import Button from "./Button";
import {BASE_URL} from "./Constants";
import axios from "axios";
import ErrorBox from "./ErrorBox";
import AuthManager from "./AuthManager";

class DeleteAccount extends React.Component {

    state = {
        password: "",

        errors: null,
        errorMessage: null,
        isSending: false
    }

    isVendorPage() {
        return this.props.history.location.pathname.includes("/vendor");
    }

    constructor(props) {
        super(props);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    handleFieldChange(event) {
        this.setState({ password: event.target.value });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Konto löschen</p>
                </div>

                <div className="content">
                    <div className="iconCircle" style={{ backgroundImage: "url('/user-solid.svg')"}}/>

                    <p className="text">Geben Sie Ihr Passwort ein, um das Löschen Ihres Kontos zu bestätigen. Beachten Sie, dass alle Daten von Ihnen unwiderruflich gelöscht werden.</p>
                    <input type="password" placeholder="Mein Passwort" value={ this.state.password } onChange={ this.handleFieldChange } />

                    {
                        this.ErrorsView()
                    }

                    <div className="neo-spacer20" />

                    <Button
                        title="Konto löschen"
                        showLoadingState={ this.state.isSending }
                        isDestructive={ true }
                        onClick={ this.deleteAccount }
                        disabled={ this.state.password.length === 0 }
                    />
                </div>
            </div>
        );
    }

    deleteAccount() {
        this.setState({ isSending: true })

        axios.post(BASE_URL + '/user/profile/delete', {
            password: this.state.password,
        })
            .then(() => {
                let authManager = new AuthManager();
                authManager.logout();

                this.props.history.push('/');
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }
}

export default DeleteAccount;
