import React from 'react';
import axios from "axios";
import {BASE_URL} from "../Constants";
import ErrorBox from "../ErrorBox";
import Button from "../Button";
import DatePicker from "react-date-picker";


class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",

            errors: [],
            errorMessage: null,
            isSent: false,
            isSending: false,
            reference: "",
            date: null,
            company: "",
            firstname: "",
            lastname: "",
            street: "",
            city: "",
            zip: "",
            phone: "",
            kennzeichen: "",
            show: "form"
        };

        this.handleChange = this.handleChange.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.send = this.send.bind(this);
        this.copy = this.copy.bind(this);
    }

    onFromDateChange(event) {
        const value = event;

        this.setState(prevState => ({
            "date": value
        }));
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

     replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: (event.target.name == "reference") ? this.replaceAll(this.replaceAll(value, " ", ""), "/", "~") : value,
            errors: []
        });
    }

    send() {
        this.setState({ isSending: true });

        let found = this;
        setTimeout(() => {
            found.setState({ isSending: false, show: "final"});
        //    toast("Link wurde kopiert!")
        }, 3000);


        /*
        axios.post(BASE_URL + '/auth/reset-pin', {
            email: this.state.email,
        })
            .then(() => {

            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });

         */
    }


    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    copy() {
        navigator.clipboard.writeText("http://app.claims-report.de/ref/"+this.state.reference);
        alert("Erfolgreich kopiert!")
        return false;
    }

    Form() {
        return (
            this.state.show != "form" ? <div>


            </div> : <div>

                <div>
                    <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Fahrzeugart</p>
                    <select name={"zahlungsempfaenger"} style={{marginBottom: 0, paddingLeft: 10}} onChange={this.handleChange}>
                        <option value={""}>Bitte wählen</option>
                        <option value={"lkw"}>LKW ohne Gliederzug / Sattelzug</option>
                        <option value={"lkwfull"}>LKW mit Gliederzug / Sattelzug</option>
                        <option value={"lkwfull"}>Transporter</option>
                        <option value={"pkw"}>PKW</option>
                    </select>
                </div>

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Schadensdatum</p>
                <DatePicker
                    className="dateField"
                    value={ this.state.date }
                    onChange={ this.onFromDateChange }
                    maxDate={new Date()}
                />

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Kennzeichen</p>
                <input type="text" placeholder="Kennzeichen (optional)" name="kennzeichen" value={this.state.kennzeichen} onChange={this.handleChange} />

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold", fontSize: 20 }}>Schaden</p>

                <div style={{marginTop: 20, marginBottom: 20}}>
                    <div style={{maxWidth: 390, height: 300, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url(/top.svg)"}}>
                        <div style={{display: "flex"}}>
                            <div style={{height: 300, flex: 1, textAlign: "center"}}>
                                <a href={""} style={{display: "block", marginTop: "35%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                                <a href={""} style={{display: "block", marginTop: "40%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                                <a href={""} style={{display: "block", marginTop: "40%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                            </div>
                            <div style={{height: 300, flex: 1, textAlign: "center", marginLeft: 40, marginRight: 40}}>
                                <a href={""} style={{display: "block", marginTop: -30}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                                <a href={""} style={{display: "block", marginTop: "35%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                                <a href={""} style={{display: "block", marginTop: "70%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>

                                <a href={""} style={{display: "block", marginTop: "20%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                            </div>
                            <div style={{height: 300, flex: 1}}>
                                <a href={""} style={{display: "block", marginTop: "35%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                                <a href={""} style={{display: "block", marginTop: "40%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                                <a href={""} style={{display: "block", marginTop: "40%"}}>
                                    <img src={"/plus.multiuse.svg"} style={{width: 30}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold", fontSize: 20 }}>Deine Daten</p>

                <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Firma (optional)</p>
                <input type="text" placeholder="Firma (optional)" name="company" value={this.state.company} onChange={this.handleChange} />

                <div style={{flexDirection: "row", display: "flex"}}>
                    <div style={{flex: 1, marginRight: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Vorname</p>
                        <input type="text" placeholder="Vorname" name="firstname" value={this.state.firstname} onChange={this.handleChange} />
                    </div>
                    <div style={{flex: 1, marginLeft: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Nachname</p>
                        <input type="text" placeholder="Nachname" name="lastname" value={this.state.lastname} onChange={this.handleChange} />
                    </div>
                </div>

                <div style={{flex: 1}}>
                    <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Straße</p>
                    <input type="text" placeholder="Straße" name="street" value={this.state.street} onChange={this.handleChange} />
                </div>

                <div style={{flexDirection: "row", display: "flex"}}>

                    <div style={{flex: .5, marginRight: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>PLZ</p>
                        <input type="text" placeholder="PLZ" name="zip" value={this.state.zip} onChange={this.handleChange} />
                    </div>
                    <div style={{flex: 1, marginLeft: 15}}>
                        <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Stadt</p>
                        <input type="text" placeholder="Ort" name="city" value={this.state.city} onChange={this.handleChange} />
                    </div>
                </div>


                <div style={{flex: 1}}>
                    <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>Telefon</p>
                    <input type="text" placeholder="Telefon" name="phone" value={this.state.phone} onChange={this.handleChange} />
                </div>
                <div style={{flex: 1}}>
                    <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "0px", fontWeight: "bold" }}>E-Mail</p>
                    <input type="text" placeholder="E-Mail" name="email" value={this.state.email} onChange={this.handleChange} />
                </div>


                {
                    this.ErrorsView()
                }

                <Button title="Schaden melden"
                           showLoadingState={ this.state.isSending }
                           onClick={ this.send }
                           disabled={ this.state.reference.length === 0 }
                />
            </div>
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <div style={{display: "flex"}}>
                        <div>
                            <img src={'/logo.jpg'} style={{ width: 100}}/>
                        </div>
                    </div>
                    <p className="titlebar-title">Schadensmeldung</p>
                </div>

                <div className="content">
                    {
                        this.Form()
                    }
                </div>
            </div>
        );
    }
}

export default Index;
