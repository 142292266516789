import React from 'react';
import './App.css';
import Button from "./Button";
import axios from "axios";
import {BASE_URL} from "./Constants";
import ErrorBox from "./ErrorBox";

class ChatOverlay extends React.Component {

    state = {
        message: "",
        errorMessage: null
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: []
        });
    }

    sendMessage() {
        const { id } = this.props.match.params

        this.setState({  isSending: true });

        let message = {
            message: this.state.message,
            type: "text"
        }

        axios.post(BASE_URL + '/chat/channels-create', {
            import_request_id: id,
            message: message,
        })
            .then(() => {
                this.cancel();
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }

    cancel() {
        const { id } = this.props.match.params

        this.props.history.push("/importlist/" + id);
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Frage zum Importwunsch</p>
                </div>

                <div className="content">

                    <input type="text" placeholder="Stell eine Frage..." name="message" value={ this.state.message } onChange={this.handleChange} />

                    {
                        this.ErrorsView()
                    }

                    <Button title="Senden"
                            showLoadingState={ this.state.isSending }
                            onClick={ this.sendMessage }
                            disabled={ this.state.message.length === 0 }
                    />

                    <Button title="Abbrechen"
                            onClick={ this.cancel }
                    />
                </div>
            </div>
        );
    }
}

export default ChatOverlay;
