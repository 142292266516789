import React from 'react';
import './Button.css';

import PropTypes from "prop-types";

function Button(props) {
    return (
        <button
            className="button"
            id={ props.isDestructive ? "destructive" : props.showLoadingState ? "loading" : null }
            disabled={ props.disabled }
            onClick={ props.showLoadingState ? null : props.onClick }
            style={ props.style }
        >
            {
                props.showLoadingState ?
                    <div className="button-loadingContainer">
                        <div className="loadingSpinner-small">
                            <div className="double-bounce1" />
                            <div className="double-bounce2" />
                        </div>
                    </div>
                    :
                    props.title
            }
        </button>
    );
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    isDestructive: PropTypes.bool,
    showLoadingState: PropTypes.bool
};

export default Button;
