import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import ImportListCell from "./ImportListCell";
import ChatListCell from "./ChatListCell";
import LoadingSpinner from "./LoadingSpinner";

class ChatList extends React.Component {

    state = {
        isLoading: true,
        list: []
    }

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.loadData();
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    List() {
        return (
            this.state.list.map(item =>
                <ChatListCell key={ item.id } item={item} />
            )
        );
    }

    loadData() {
        axios.get(BASE_URL + "/chat/channels")
            .then(result => {
                const list = [];

                result.data.data.map(item => item.to_user == null ? null : list.push(item));

                this.setState({
                    isLoading: false, list
                });
            });
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Nachrichten</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ?
                            this.LoadingView()
                            :
                            this.state.list.length > 0 ?
                                this.List() :
                                "Keine Nachrichten"
                    }
                </div>
            </div>
        );
    }
}

export default ChatList;
