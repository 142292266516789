import React from 'react';
import ErrorBox from "./ErrorBox";
import Button from "./Button";
import axios from "axios";
import DatePicker from 'react-date-picker'
import {BASE_URL} from "./Constants";
import ConfirmationView from "./ConfirmationView";
import { format, parse } from 'date-fns'

class AcceptImportwish extends React.Component {

    state = {
        fromDate: null,
        toDate: null,
        price: "",
        errorMessage: null,
        isSending: false,
        isSent: false
    }

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);

        this.send = this.send.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    onChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(prevState => ({
            [name]: value
        }));
    }

    onFromDateChange(event) {
        const value = event;

        this.setState(prevState => ({
            "fromDate": value
        }));
    }

    onToDateChange(event) {
        const value = event;

        this.setState(prevState => ({
            "toDate": value
        }));
    }

    send() {
        const { id } = this.props.match.params

        this.setState({  isSending: true });

        axios.post(BASE_URL + '/import-request/premium-offer/' + id, {
            delivery_from: format(this.state.fromDate, "yyyy-MM-dd"),
            delivery_to: format(this.state.toDate, "yyyy-MM-dd"),
            price: this.formattedPrice()
        })
            .then(() => {
                this.setState({ isSending: false, isSent: true });
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    cancel() {
        const { id } = this.props.match.params

        this.props.history.push("/importlist/" + id);
    }

    formattedPrice() {
        let price = this.state.price ? this.state.price : "0";

        return parseFloat(price);
    }

    Content() {
        return (
           <div>
               <input name="price" type="text" placeholder="Preis in Euro" value={ this.state.price } onChange={ this.onChange } />

               <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Lieferzeitraum</p>

               <DatePicker
                   className="dateField"
                   value={ this.state.fromDate }
                   onChange={ this.onFromDateChange }
                   minDate={ new Date() }
               />

               <DatePicker
                   className="dateField"
                   value={ this.state.toDate }
                   onChange={ this.onToDateChange }
                   minDate={ new Date() }
               />

               { this.ErrorsView() }


               <Button title="Angebot senden"
                       onClick={ this.send }
                       showLoadingState={ this.state.isSending }
                       disabled={ this.state.price.length === 0 || this.state.fromDate === null || this.state.toDate === null }
               />

               <Button title="Abbrechen"
                       onClick={ this.cancel }
               />
           </div>
    );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Angebot senden</p>
                </div>

                <div className="content">
                    {
                        this.state.isSent ? <ConfirmationView text="Ihr Angebot wurde erfolgreich gesendet." icon="check-solid.svg" /> : this.Content()
                    }
                </div>
            </div>
        );
    }

}

export default AcceptImportwish;
