import React from 'react';
import {Link} from "react-router-dom";
import ErrorBox from "./ErrorBox";
import Button from "./Button";
import axios from "axios";
import AuthManager from "./AuthManager";
import {BASE_URL, LOGIN_ENDPOINT} from "./Constants";

class Login extends React.Component {

    state = {
        email: "",
        password: "",
        incorrectCredentials: false,
        errorMessage: null,
        isSending: false
    }

    constructor(props) {
        super(props);

        this.handleEmailFieldChange = this.handleEmailFieldChange.bind(this);
        this.handlePasswordFieldChange = this.handlePasswordFieldChange.bind(this);

        this.login = this.login.bind(this);
    }

    handleEmailFieldChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordFieldChange(event) {
        this.setState({password: event.target.value});
    }

    login() {
        this.setState({ isSending: true });

        axios.post(BASE_URL + LOGIN_ENDPOINT, {
            email: this.state.email,
            password: this.state.password
        })
            .then(result => {
                const loginResult = result.data;

                let authManager = new AuthManager();
                authManager.login(loginResult.access_token, loginResult.expires_at);

                this.props.history.push('/ref/'+this.state.email);
            })
            .catch(error => {
                console.log(error)
                if (error.response) {
                    this.setState({ isSending: false, errorMessage: "Schadensnummer / Referenznummer nicht gefunden" });
                }
            });
    }


    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    render() {
        return (
            <div className="wrapper">

                <div className="content">
                    <div style={{ width: 300, margin: "auto", textAlign: "center"}}>
                        <img src={'/logo.jpg'} style={{ width: 300, margin: "auto", textAlign: "center"}}/>
                    </div>

                    <p className="text" style={{marginTop: 30, marginBottom: 30}}>Bitte trage deine Schadensnummer ein.</p>

                    <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>Schadensnummer / Referenznummer</p>
                    <input type="text" placeholder="Schadensnummer / Referenznummer" value={ this.state.email } onChange={ this.handleEmailFieldChange } />

                    { this.ErrorsView() }

                    <Button title="Schaden suchen"
                            onClick={ this.login }
                            showLoadingState={ this.state.isSending }
                            disabled={this.state.email.length === 0}
                    />

                </div>
            </div>
        );
    }

}

export default Login;
