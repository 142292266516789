import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Main from "./Main";
import NavBar from './NavBar';
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom"
import PageNotFound from "./PageNotFound";
import Login from "./Login";
import AuthManager from "./AuthManager";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import ForgotPassword from "./ForgotPassword";
import SetNewPassword from "./SetNewPassword";
import ImportList from "./ImportList";
import Footer from "./Footer";
import ImportDetail from "./ImportDetail";
import Add from "./Add";
import Imprint from "./Imprint";
import Co2 from "./Co2";
import Regulatory from "./Regulatory";
import AcceptImportwish from "./AcceptImportwish";
import ChatList from "./ChatList";
import ChatConversation from "./ChatConversation";
import ChatOverlay from "./ChatOverlay";

import AgentIndex from "./agent/index";
import RefIndex from "./Reference/index";

function App() {
  let history = useHistory();

  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${ Cookies.get("AccessToken") }`;

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401 && !error.response.config.url.includes("login")) {
      showLogin();
    } else {
      return Promise.reject(error);
    }
  });

  function showLogin() {
    history.push('/login');
  }

  return (
      <div>
        <NavBar/>

        <Switch>
          <Route exact path='/agent/index' component={ AgentIndex } />
          <Route exact path='/ref/:id' component={ RefIndex } />
          <Route exact path='/login' component={ Login } />
          <Route exact path='/forgotPassword' component={ ForgotPassword } />
          <Route exact path='/forgotPassword/set' component={ SetNewPassword } />
          <Route exact path='/imprint' component={ Imprint } />
          <Route exact path='/regulatory' component={ Regulatory } />

          <PrivateRoute exact path='/add' component={ Add } />
          <PrivateRoute exact path='/co2' component={ Co2 } />
          <PrivateRoute exact path='/importlist/:id' component={ ImportDetail } />
          <PrivateRoute exact path='/importlist/:id/accept' component={ AcceptImportwish } />
          <PrivateRoute exact path='/importlist/:id/ask' component={ ChatOverlay } />

          <PrivateRoute exact path='/chat' component={ ChatList } />
          <PrivateRoute exact path='/chat/:id' component={ ChatConversation } />

          <PrivateRoute exact path='/profile' component={ Profile } />
          <PrivateRoute path='/profile/password' component={ ChangePassword } />
          <PrivateRoute path='/profile/delete' component={ DeleteAccount } />

          <PrivateRoute exact path='/' component={ ImportList } />

          <Route path='*' component={ PageNotFound } />
        </Switch>

        <Footer/>

      </div>
  );
}

function PrivateRoute ({component: Component, authed, ...rest}) {
  let authManager = new AuthManager();

  return (
      <Route
          {...rest}
          render={(props) => authManager.isAuthenticated() === true
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
  )
}

export default App;
