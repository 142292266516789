import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import ImportListCell from "./ImportListCell";
import LoadingSpinner from "./LoadingSpinner";

class ImportList extends React.Component {

    state = {
        isLoading: true,
        list: []
    }

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get(BASE_URL + "/import-request/index")
            .then(result => {
                const list = result.data.data;
                const founds = [];
                if(list.length > 0) {
                    list.map((x, key) => {
                        if(x.status != "deactivated") {
                           founds.push(x);
                        }

                    });
                }
                this.setState({
                    isLoading: false, list: founds
                });
            });
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    List() {
        return (
            this.state.list.map(item =>
                <ImportListCell key={item.id} item={item} />
            )
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Fundsachen</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ?
                            this.LoadingView()
                            :
                            this.state.list.length > 0 ?
                                this.List() :
                                "Keine Fundsachen"
                    }
                </div>
            </div>
        );
    }
}

export default ImportList;
