import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import ImportListCell from "./ImportListCell";
import LoadingSpinner from "./LoadingSpinner";

class Co2 extends React.Component {

    state = {
        isLoading: true,
        list: []
    }

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get(BASE_URL + "/dashboard/index")
            .then(result => {
                const list = result.data;

                this.setState({
                    isLoading: false, list: list
                });
            });
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }


    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Co2 Ersparnis</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ?
                            this.LoadingView()
                            :
                            <div style={{width: "100%", borderRadius: 20, padding: 20, backgroundColor: "#0c9dab"}}>
                                <h1 style={{color: '#fff', textAlign: "center", fontSize: 45, marginBottom: 0}}>{this.state.list.co2.savings}</h1>
                                <h3 style={{color: '#fff', textAlign: "center", marginBottom: 0, marginTop: 20}}>Unser CO2-Ersparnis in KG</h3>
                                <p style={{color: '#fff', textAlign: "center", marginBottom: 0, marginTop: 5}}>mit {this.state.list.co2.trips} Lieferungen</p>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default Co2;
