import React from 'react';
import './App.css';

function PageNotFound() {
    return (
        <div className="wrapper">
            <div className="titlebar">
                <p className="titlebar-title">Diese Seite wurde nicht gefunden</p>
            </div>

            <div className="content">

            </div>
        </div>
    );
}

export default PageNotFound;
