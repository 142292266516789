import React from 'react';
import { withRouter } from 'react-router';

class Navbar extends React.Component {

    state = {
        isMenuVisible: false
    };

    constructor(props) {
        super(props);
        this.showCo2 = this.showCo2.bind(this);
        this.showImportList = this.showImportList.bind(this);
        this.showChat = this.showChat.bind(this);
        this.showProfile = this.showProfile.bind(this);
        this.showAdd = this.showAdd.bind(this);
    }

    currentPageIsAdd() {
        return this.props.history.location.pathname.includes("add");
    }

    currentPageIsCo2() {
        return this.props.history.location.pathname.includes("co2");
    }

    currentPageIsImportlist() {
        return this.props.history.location.pathname === "/" || this.props.history.location.pathname.includes("importlist");
    }
    currentPageIsChat() {
        return this.props.history.location.pathname.includes("chat");
    }

    currentPageIsProfile() {
        return this.props.history.location.pathname.includes("profile");
    }

    render() {
        return ""
        return (
            <div className="navbar">
                <div className="navbar-menu-container">
                    <div className="navbar-menu-item" id={ this.currentPageIsCo2() ? "selected": null }
                         style={{ backgroundImage: "url('/co2.svg')" }}
                         onClick={ this.showCo2 } />

                    <div className="navbar-menu-item" id={ this.currentPageIsImportlist() ? "selected": null }
                         style={{ backgroundImage: "url('/importlist.svg')" }}
                         onClick={ this.showImportList } />

                    <div className="navbar-menu-item" id={ this.currentPageIsChat() ? "selected": null }
                         style={{ backgroundImage: "url('/comments-regular.svg')", backgroundSize: "28px" }}
                         onClick={ this.showChat } />

                    <div className="navbar-menu-item" id={ this.currentPageIsAdd() ? "selected": null }
                         style={{ backgroundImage: "url('/add_plus.svg')" }}
                         onClick={ this.showAdd } />

                    <div className="navbar-menu-item" id={ this.currentPageIsProfile() ? "selected": null }
                         style={{ backgroundImage: "url('/user-regular.svg')", backgroundSize: "20px" }}
                         onClick={ this.showProfile } />
                </div>
            </div>
        );
    }

    showImportList() {
        this.props.history.push('/');
    }

    showCo2() {
        this.props.history.push('/co2');
    }

    showAdd() {
        this.props.history.push('/add');
    }

    showChat() {
        this.props.history.push('/chat');
    }

    showProfile() {
        this.props.history.push('/profile');
    }
}

export default withRouter(Navbar);
