import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import ChatBubble from "./ChatBubble";
import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";
import ChatListCell from "./ChatListCell";
import {Link} from "react-router-dom";

class ChatConversation extends React.Component {

    state = {
        message: "",
        isLoading: true,
        isSending: false,
        list: []
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.chooseFile = this.chooseFile.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const { id } = this.props.match.params

        axios.get(BASE_URL + "/chat/channels/" + id + "/messages")
            .then(result => {
                const list = result.data.messages.data;
                const channel = result.data.channel;
                console.log(result);

                this.setState({
                    isLoading: false, list, channel
                });
            });
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: []
        });
    }

    sendMessage() {
        const { id } = this.props.match.params

        this.setState({  isSending: true });

        axios.post(BASE_URL + '/chat/channels/' + id + '/add-message', {
            type: "text",
            message: this.state.message
        })
            .then(() => {
                this.loadData();

                this.setState({
                    message: "",
                    isSending: false
                });
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });
    }

    chooseFile() {
        this.state.inputElement.click();
    }

    onFileChange(event) {
        const { id } = this.props.match.params

        this.setState({  isSending: true });

        const url = BASE_URL + '/chat/channels/' + id + '/add-message';
        const formData = new FormData();
        formData.append('image1', event.target.files[0]);
        formData.append('type', "image");
        formData.append('message', "");

        const config = {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data'
            }
        }

        return axios.post(url, formData, config)
            .then(() => {
                this.setState({
                    isSent: true,
                    isSending: false
                });

                this.loadData();
            }).catch(error => {
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors ?? null, errorMessage: error.response.data.message });
                }
            });
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    List() {
        return (
            <div>
                {
                    this.state.list.map(item =>
                        <ChatBubble key={item.id} item={item} />
                    )
                }
            </div>
        );
    }

    ProfileView() {
        return (
            <div className="chat-profileBanner">
                <div className="chat-profileBanner-left">
                    {
                        this.state.channel.to_user.image !== null ?
                            <div className="chat-profileBanner-image" style={{ backgroundImage: "url('" + this.state.channel.to_user.image.url + "')" }} />
                            :
                            <div className="chat-profileBanner-image" style={{ backgroundImage: "url('/user-solid.svg')", backgroundSize: "26px" }} />
                    }
                </div>

                <div className="profileBanner-right">
                    <p className="profileBanner-label" style={{ fontWeight: "700" }}>{ this.state.channel.to_user.profile.firstname } { this.state.channel.to_user.profile.lastname }</p>
                    <p className="profileBanner-label" style={{ fontWeight: "500", color: "gray" }}><Link to={ "/importlist/" + this.state.channel.id }>{ this.state.channel.import_request.name }</Link></p>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="wrapper">



                <div className="content" style={{ paddingTop: "160px" }}>
                    {
                        this.state.isLoading === false ?
                            this.ProfileView()
                            :
                            null
                    }

                    {
                        this.state.isLoading ?
                            this.LoadingView()
                            :
                            this.List()
                    }

                    <div className="chatTextField-container">
                        <button
                            className="chatTextField-imageButton"
                            title="Bild anhängen"
                            onClick={ this.chooseFile }
                        />

                        <form>
                            <input type="file" className="hiddenUpload" accept=".png,.jpeg,.jpg,.gif" onChange={ this.onFileChange }
                                   ref={input => this.state.inputElement =  input }
                            />
                        </form>

                        <input type="text"
                               placeholder="Nachricht eingeben" name="message"
                               value={this.state.message} onChange={this.handleChange}
                               style={{ marginTop: "20px" }}
                        />

                        <button
                            className="chatTextField-sendButton"
                            title="Senden"
                            onClick={ this.sendMessage }
                            disabled={ this.state.message.length === 0 || this.state.isSending }
                        />
                    </div>
                </div>


            </div>
        );
    }
}

export default ChatConversation;
