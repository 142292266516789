import React from 'react';
import {useHistory} from "react-router-dom";
import moment from "moment";

function ImportListCell(props) {
    let history = useHistory();

    function showDetails() {
        history.push("/importlist/" + props.item.id);
    }

    return (
        <div className="importItem-cell" onClick={ showDetails }>
            <div className="importItem-cell-left">
                {
                    props.item.images.length > 0 ?
                        <div className="importItem-cell-image" style={{ backgroundImage: "url('" + props.item.images[0].url + "')" }} />
                        :
                        <div className="importItem-cell-image" style={{ backgroundImage: "url('/image-regular.svg')", backgroundSize: "60px" }} />
                }
            </div>

            <div className="importItem-cell-right">
                <p className="importItem-cell-label" style={{ display: "none", color: "gray" }}>{ props.item.user.name }</p>
                <p className="importItem-cell-label" style={{ fontWeight: "700" }}>{ props.item.name }</p>
                <p className="importItem-cell-label" style={{ fontWeight: "400" }}>Status: {props.item.status == "waiting_for_payment" ? 'Zahlung erwartet' : props.item.status == 'accepted' ? 'Connect' : null}{props.item.status == 'open' ? 'Rückfrage' : null}{props.item.status == 'handover' ? 'Handover' : null}{props.item.status == 'shopped' ? 'Deliver' : null}{props.item.status == 'completed' || props.item.status =="handover_completed" ? 'Done' : null}</p>
                <p className="importItem-cell-label" style={{ fontWeight: "400" }}>{ moment(props.item.date_from).format("DD.MM.Y") } - { moment(props.item.date_to).format("DD.MM.Y") }</p>
                <p className="importItem-cell-label" style={{ display: "none", color: "gray" }}>Abholdatum: { props.item.price_min } - { props.item.price_max } €</p>
            </div>
        </div>
    );
}

export default ImportListCell;
