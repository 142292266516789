import React from 'react';
import PropTypes from "prop-types";

function ErrorBox(props) {

    function localizeIfPossible(text) {
        if (text === "The given data was invalid.") {
            return "Die eingegebenen Daten sind nicht gültig.";
        } else if (text === "Unauthenticated.") {
            return "Zugriff verweigert.";
        }

        return text
    }

    return (
        <div className="errorContainer">
            <p className="errorText" id="leading">{ localizeIfPossible(props.errorMessage) }</p>

            {
                props.errors ? Object.keys(props.errors).map((key, index) => (
                        <p className="errorText" id="inset" key={index}>{props.errors[key]}</p>
                    ))
                    : null
            }
        </div>
    );
}

ErrorBox.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    errors: PropTypes.array
};

export default ErrorBox;
