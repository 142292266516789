import React from 'react';
import axios from "axios";
import {BASE_URL} from "./Constants";
import ConfirmationView from "./ConfirmationView";
import ErrorBox from "./ErrorBox";
import Button from "./Button";

class SetNewPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pin: "",
            newPassword: "",
            newPasswordConfirmation: "",

            errors: [],
            errorMessage: null,
            isSent: false,
            isSending: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value,
            errors: []
        });
    }

    changePassword() {
        this.setState({ isSending: true });

        axios.post(BASE_URL + '/auth/reset', {
            reset_pin: this.state.pin,
            new_password: this.state.newPassword,
            new_password_confirmation: this.state.newPasswordConfirmation
        }).then(() => {
            this.setState({ isSending: false, isSent: true });
        }).catch(error => {
            if (error.response) {
                this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
            }
        });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    Form() {
        return (
            <div>
                <input type="text" placeholder="PIN" name="pin" value={this.state.pin} onChange={this.handleChange} />
                <input type="password" placeholder="Neues Passwort" name="newPassword" value={this.state.newPassword} onChange={this.handleChange} />
                <input type="password" placeholder="Neues Passwort wiederholen" name="newPasswordConfirmation" value={this.state.newPasswordConfirmation} onChange={this.handleChange} />

                <p className="neo-text">Das Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie Buchstaben enthalten.</p>

                {
                    this.ErrorsView()
                }

                <Button title="Passwort ändern"
                           showLoadingState={ this.state.isSending }
                           onClick={ this.changePassword }
                           disabled={this.state.pin.length === 0 || this.state.newPassword.length === 0|| this.state.newPasswordConfirmation.length === 0}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Passwort ändern</p>
                </div>

                <div className="content">
                    {
                        this.state.isSent ? <ConfirmationView text="Sie haben Ihr Passwort erfolgreich geändert." icon="check-solid.svg"/> : this.Form()
                    }
                </div>
            </div>
        );
    }
}

export default SetNewPassword;
