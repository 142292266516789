import React from 'react';
import './SelectField.css';
import PropTypes from "prop-types";

class SelectField extends React.Component {

    render() {
        return (
            <div className="editField-container">
                <p className="editField-label">{ this.props.label }</p>
                <select dir="rtl" className="SelectField-select" id="select"
                        name={ this.props.name } onChange={ this.props.onChange }
                        disabled={ this.props.disabled } value={ this.props.value }
                >
                    {
                        this.props.items.map(item =>
                            item
                        )
                    }
                </select>
            </div>
        );
    }
}

SelectField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};

export default SelectField;
